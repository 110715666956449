import { IEnvironment } from 'config/env';

const environment: IEnvironment = {
  ENV: 'elive',
  SUPPORT_URL: 'https://ef-teachonline-helpcenter.force.com/s/',
  GQL_URL: 'https://api.dev.ef.studio/gipfeli-elive-qa/',
  MEDIA_URL: 'https://teach-assets-elive-qa.ef.studio',
  TF_SVCGATEWAY_URL: 'https://api.dev.ef.studio/teacher-first-elive-qa',
  ENTRY_STATUS_INTERVAL: 2e4,
  AVAILABILITY_INTERVAL: 1e4,
  BOOKING_INTERVAL: 1e4,
  USER_INTERVAL: 6e5,
  UNCLAIMED_LESSONS_INTERVAL: 1e4,
  DISCONNECT_AFTER: 3e5,
  HELP_CENTER_URL: 'https://staging-englishlive.cs113.force.com/helpcenter',
  ACR_TODO_INTERVAL: 6e4,
  LESSON_DEFINITION_INTERVAL: 6e4,
  TECH_CHECK_URL_TEMPLATE:
    'https://evc.ef.com/evc15/meeting/tools/teachertechcheck?proxyInfo=&centerCode=UK#ret={0};s=304615050;m=35754986;cc=en;host=axis.eflabs.io;canskip=true',
  LOGIN: {
    SIGNIN_SIGNUP_USER_FLOW: 'B2C_1_Teacher_First_Non_Prod',
    RESET_USER_FLOW: 'B2C_1_Teacher_First_Non_Prod',
    AUTHORITY_BASE_URL: 'https://efiddev.b2clogin.com/efiddev.onmicrosoft.com/',
    TOKEN_SCOPE: 'https://efiddev.onmicrosoft.com/tfapi/tfapi.readwrite',
    CLIENT_ID: '6f1ca649-b440-4c02-8e43-f65e4e493ca5',
  },
  DATADOG: {
    APPLICATION_ID: '9d00dff8-74e2-4ba3-b391-1fdd000b21aa',
    CLIENT_TOKEN: 'pub0242cd2f1e3f4db91a4a78865b52fe00',
    SERVICE_NAME: 'teacher-first-fe',
    VIRTUAL_ENV: 'elive-qa',
  },
  API_KEYS: {},
  EVC_TUNNELS: {
    US_NV: 'https://dev.evc.ef.com',
    CN_SH: 'https://qa-evc.ef.com.cn',
  },
};

export default environment;
