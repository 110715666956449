export enum APP_NAMES {
  OP = 'Ops Portal',
  TF = 'Teacher First',
}

enum Pages {
  ACR = 'ACR',
  Calendar = 'Calendar',
  ClassPreparation = 'Class Preparation',
  CreateTeacher = 'Create Teacher',
  Groups = 'Groups',
  LessonHistory = 'Lesson History',
  LiveClassMonitor = 'Live class monitor',
  Scheduling = 'Scheduling',
  SchoolSettings = 'School Settings',
  StudentPortal = 'Student Portal',
  Students = 'Students',
  Teachers = 'Teachers',
  Dashboard = 'Dashboard',
  Lobby = 'Lobby',
}

const getPageTitle = (appName: string, page: string) => `${page} - ${appName}`;

const getCommonPages = (appName: APP_NAMES) => ({
  ACR: getPageTitle(appName, Pages.ACR),
  Calendar: getPageTitle(appName, Pages.Calendar),
  ClassPreparation: getPageTitle(appName, Pages.ClassPreparation),
  LessonHistory: getPageTitle(appName, Pages.LessonHistory),
  StudentGroups: getPageTitle(appName, Pages.Groups),
});

export const TF_PAGES = {
  ...getCommonPages(APP_NAMES.TF),
  Lobby: getPageTitle(APP_NAMES.TF, Pages.Lobby),
  Dashboard: getPageTitle(APP_NAMES.TF, Pages.Dashboard),
};

export const OP_PAGES = {
  ...getCommonPages(APP_NAMES.OP),
  CreateTeacher: getPageTitle(APP_NAMES.OP, Pages.CreateTeacher),
  Scheduling: getPageTitle(APP_NAMES.OP, Pages.Scheduling),
  LiveMonitor: getPageTitle(APP_NAMES.OP, Pages.LiveClassMonitor),
  SchoolSettings: getPageTitle(APP_NAMES.OP, Pages.SchoolSettings),
  StudentPortal: getPageTitle(APP_NAMES.OP, Pages.StudentPortal),
  Groups: getPageTitle(APP_NAMES.OP, Pages.Groups),
  Teachers: getPageTitle(APP_NAMES.OP, Pages.Teachers),
};
