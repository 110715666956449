import { IEnvironment } from 'config/env';

const environment: IEnvironment = {
  SUPPORT_URL: 'https://ef-teachonline-helpcenter.force.com/s/',
  GQL_URL: 'https://api.dev.ef.studio/gipfeli/',
  MEDIA_URL: 'https://teach-assets.dev.ef.studio',
  TF_SVCGATEWAY_URL: 'https://api.dev.ef.studio/teacher-first',
  ENTRY_STATUS_INTERVAL: 2e4,
  AVAILABILITY_INTERVAL: 1e4,
  BOOKING_INTERVAL: 1e4,
  USER_INTERVAL: 6e5,
  UNCLAIMED_LESSONS_INTERVAL: 1e4,
  ACR_TODO_INTERVAL: 6e4,
  LESSON_DEFINITION_INTERVAL: 6e4,
  TECH_CHECK_URL_TEMPLATE:
    'https://evc.ef.com/evc15/meeting/tools/teachertechcheck?proxyInfo=&centerCode=UK#ret={0};s=304615050;m=35754986;cc=en;host=axis.eflabs.io;canskip=true',
  DISCONNECT_AFTER: 3e5,
  LOGIN: {
    SIGNIN_SIGNUP_USER_FLOW: 'B2C_1_Ops_Portal_Non_Prod',
    RESET_USER_FLOW: 'B2C_1_Ops_Portal_Non_Prod',
    AUTHORITY_BASE_URL: 'https://efiddev.b2clogin.com/efiddev.onmicrosoft.com/',
    CLIENT_ID: '6f1ca649-b440-4c02-8e43-f65e4e493ca5',
    TOKEN_SCOPE: 'https://efiddev.onmicrosoft.com/tfapi/tfapi.readwrite',
  },
  API_KEYS: {},
  ENV: 'dev',
  EVC_TUNNELS: {
    US_NV: 'https://dev.evc.ef.com',
    CN_SH: 'https://qa-evc.ef.com.cn',
  },
  HELP_CENTER_URL: '',
};

export default environment;
