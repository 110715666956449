import { Grid } from '@mui/material';
import React, { useEffect } from 'react';

import { PrimaryButton } from 'components/Button';
import { getUrlForEvc, getEvcUrl } from 'utils/bookings';

import * as S from './hyperclass-web.styled';

interface HyperClassWebButtonProps {
  teacherId: string;
}

const HyperClassWebButton = ({ teacherId }: HyperClassWebButtonProps) => {
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [bookingUrl, setBookingUrl] = React.useState<string | null>(null);
  useEffect(() => {
    const uri = getEvcUrl(window.location.hostname.includes('.cn') ? '.cn' : '.com');
    const evcUrl = getUrlForEvc(uri, '0');
    setBookingUrl(evcUrl);
  }, [teacherId]);

  const openInNewTab = () => bookingUrl && setModalOpen(true);

  return (
    <S.Wrapper container flexDirection="column" spacing={2}>
      <Grid item>
        <S.HeaderText>Hyperclass</S.HeaderText>
      </Grid>
      <Grid item>
        <PrimaryButton disabled={!bookingUrl} onClick={openInNewTab}>
          Preview content
        </PrimaryButton>
      </Grid>
      <S.EvcModal open={isModalOpen} onClose={() => setModalOpen(false)}>
        <S.EvcModalContentWrapper>
          {bookingUrl ? (
            <S.EvcModalContent src={bookingUrl} allow="microphone; camera; autoplay; speaker-selection" />
          ) : (
            <></>
          )}
        </S.EvcModalContentWrapper>
      </S.EvcModal>
    </S.Wrapper>
  );
};

export default HyperClassWebButton;
