import { Moment } from 'moment-timezone';
import { generatePath } from 'react-router-dom';

import { ViewMode } from 'model/Calendar';

// OPSPORTAL ROUTES
export const OP_TEACHER_SEARCH_PATH = '/teachers';
export const OP_STUDENT_SEARCH_PATH = '/students';
export const OP_STUDENT_GROUP_SEARCH_PATH = '/students/group';
export const OP_STUDENT_UNCONFIRMED_LESSON_SEARCH_PATH = '/students/group/unassigned-groups';

export const OP_STUDENT_GROUP_DETAILS_PATH = '/students/group/:groupId';
export const getStudentGroupDetailsPath = (groupId: string) => generatePath(OP_STUDENT_GROUP_DETAILS_PATH, { groupId });
export const OP_LESSONS_PATH = '/lessons';
export const OP_UNASSIGNED_LESSONS_PATH = '/lessons/unassigned-lessons';
export const OP_LESSON_SCHEDULING_PATH = '/lessons/lesson-scheduling';
export const OP_GROUP_LESSON_SCHEDULING_PATH = '/lessons/group-lesson-scheduling';
export const OP_STUDENT_LIVE_MONITOR_PATH_MAIN = '/live-monitor';
export const OP_STUDENT_LIVE_MONITOR_PATH_ALL = '/live-monitor/all';
export const OP_STUDENT_LIVE_MONITOR_PATH_EMPTY = '/live-monitor/empty';

export const OP_CALENDAR_EDIT_ROUTE = '/calendar/:teacherId/edit';
export const OP_CALENDAR_ROUTE = '/calendar/:teacherId';
export const getAdminCalendarPath = (teacherId: string) => `/calendar/${teacherId}`;
export const getAdminCalendarEditPath = (teacherId: string, time: number) => `/calendar/${teacherId}/edit?time=${time}`;

export const OP_LESSON_HISTORY_CLASS_RECORDS = '/lesson-history';
export const OP_LESSON_HISTORY_CLASS_RECORD_DETAILS = '/lesson-history/:bookingId';
export const getLessonHistoryPath = (bookingId: string = ':bookingId') => `/lesson-history/${bookingId}`;
export const OP_LESSON_HISTORY_INVOICES = '/lesson-history/invoices';
export const OP_LESSON_HISTORY_INVOICE = '/lesson-history/invoices/:invoiceId';
export const getAdminInvoicePath = (invoiceId: string = ':invoiceId') => `/lesson-history/invoices/${invoiceId}`;
export const OP_LESSON_HISTORY_STUDENT_REVIEWS = '/lesson-history/student-reviews';
export const OP_LESSON_HISTORY_CLASS_SUMMARY = '/lesson-history/class-summary';

export const OP_STUDENT_PORTAL_LESSONS = '/student-portal/lessons';
export const OP_STUDENT_PORTAL_LESSONS_DETAILS = '/student-portal/lessons/:bookingId';
export const getAdminStudentPortalLessonPath = (bookingId: string = ':bookingId') =>
  `/student-portal/lessons/${bookingId}`;

export const OP_SCHOOL_SETTINGS = '/school-settings';
export const OP_SCHOOL_SETTINGS_LESSON_DEFINITIONS = '/school-settings/lesson-definitions';
export const OP_SCHOOL_SETTINGS_BLOCK_LIST = '/school-settings/block-list';
export const OP_SCHOOL_SETTINGS_ANNOUNCEMENTS = '/school-settings/announcements';
export const OP_SCHOOL_SETTINGS_BLOCK_SLOTS = '/school-settings/blocked-slots';
export const OP_SCHOOL_SETTINGS_GENERATE_INVOICES = '/school-settings/generate-invoices';
export const OP_SCHOOL_SETTINGS_EDIT_DISTRIBUTIONS = '/school-settings/distributions';

export const OP_CREATE_TEACHER_PATH = '/create-teacher';

export const OP_HOME_PATH = OP_TEACHER_SEARCH_PATH;

// TEACHERFIRST ROUTES
export const TF_DASHBOARD_ROUTE = '/dashboard';

export const TF_CALENDAR_EDIT_ROUTE = '/calendar/edit';
export const TF_CALENDAR_ROUTE = '/calendar';
export const getTeacherCalendarSearch = (selectedDate: Moment, viewMode: ViewMode, time: number) =>
  `?date=${selectedDate.format('YYYY-MM-DD')}&viewMode=${viewMode}&time=${time}`;

export const TF_PROFILE = '/teacher/:teacherId/profile';

export const TF_LESSON_HISTORY_OVERVIEW = '/lesson-history';
export const TF_LESSON_HISTORY_CLASS_RECORDS = '/lesson-history/class-records';
export const TF_LESSON_HISTORY_INVOICES = '/lesson-history/invoices';
export const TF_LESSON_HISTORY_INVOICE = '/lesson-history/invoices/:invoiceId';
export const getTeacherInvoicePath = (invoiceId: string = ':invoiceId') => `/lesson-history/invoices/${invoiceId}`;
export const TF_LESSON_HISTORY_STUDENT_REVIEWS = '/lesson-history/student-reviews';

export const TF_TEACHER_LOBBY_PATH = '/bookings/:bookingId/lobby';
export const getTeacherLobbyPath = (bookingId: string = ':bookingId') => `/bookings/${bookingId}/lobby`;
export const TF_STUDENT_GROUPS_PATH = '/student-groups';
export const TF_STUDENT_GROUPS_DETAILS_PATH = '/student-groups/:groupId';
export const getStudentGroupDetailsTFPath = (groupId: string) =>
  generatePath(TF_STUDENT_GROUPS_DETAILS_PATH, { groupId });
export const TF_STUDENT_ASSIGNED_GROUPS_PATH = `${TF_STUDENT_GROUPS_PATH}/assigned-groups`;
export const TF_STUDENT_UNASSIGNED_GROUPS_PATH = `${TF_STUDENT_GROUPS_PATH}/unassigned-groups`;

export const TF_HOME_PATH = TF_DASHBOARD_ROUTE;
export const getClassroomPath = (bookingId: string, participantId: string) =>
  `/bookings/${bookingId}/participants/${participantId}/classroom`;
export const TF_CLASSROOM = getClassroomPath(':bookingId', ':participantId');
export const getEvcClassroomPath = (evcClassroomId: string, participantId: string) =>
  `/evc-classrooms/${evcClassroomId}/participants/${participantId}/classroom`;
export const TF_EVC_CLASSROOM = getEvcClassroomPath(':evcClassroomId', ':participantId');

// MISC ROUTES
export const GENERAL_ERROR_PATH = '/error';
export const UNAUTHORIZED_USER_PATH = '/unauthorized';

export const HOME_PATH = '/';

export const STUDENTS_LOBBY_PATH = '/students-lobby';

// SHARED ROUTES
export const SHARED_ACR_PATH = '/acr/:acrId';
export const getAcrPath = (acrId: string = ':acrId') => `/acr/${acrId}`;

export const SHARED_CLASS_PREPARATION_PATH = '/class-preparation/:bookingId';
export const getClassPrepPath = (bookingId: string = ':bookingId') => `/class-preparation/${bookingId}`;
